import { i18n } from "@/config"

export const TIME_SLOT_LIST = [
  {
    id: "hourly",
    name: i18n.t("plan_management.time_slot_type.hourly"),
    slots: ["1_6_hours", "7_12_hours", "13_24_hours", "hourly_thereafter", "daily_thereafter"]
  },
  {
    id: "daily",
    name: i18n.t("plan_management.time_slot_type.daily"),
    slots: ["same_day", "1_night_2_days", "2_nights_3_days", "3_night_4_days", "daily_thereafter"]
  }
]

export const TIME_SLOT_TYPES = [
  {
    id: "all",
    name: i18n.t("plan_management.time_slot_type.all")
  },
  {
    id: "daily",
    name: i18n.t("plan_management.time_slot_type.daily")
  },
  {
    id: "hourly",
    name: i18n.t("plan_management.time_slot_type.hourly")
  }
]

export const STATUSES = [
  {
    id: "sale",
    name: i18n.t("plan_management.status.sale")
  },
  {
    id: "posting",
    name: i18n.t("plan_management.status.posting")
  }
]

export const PERIOD_INCLUSION_TYPES = [
  {
    id: "all_included",
    name: i18n.t("plan_management.period_inclusion_type.all_included")
  },
  {
    id: "partially_included",
    name: i18n.t("plan_management.period_inclusion_type.partially_included")
  },
  {
    id: "partially_excluded",
    name: i18n.t("plan_management.period_inclusion_type.partially_excluded")
  },
  {
    id: "all_excluded",
    name: i18n.t("plan_management.period_inclusion_type.all_excluded")
  }
]

export const INSURANCES = [
  {
    id: "all",
    name: i18n.t("plan_management.insurance.all")
  },
  {
    id: "included",
    name: i18n.t("plan_management.insurance.included")
  },
  {
    id: "optional",
    name: i18n.t("plan_management.insurance.optional")
  }
]

export const SALE_STATUSES = [
  {
    id: "all",
    name: i18n.t("plan_management.selling_status.all")
  },
  {
    id: "selling",
    name: i18n.t("plan_management.selling_status.selling")
  },
  {
    id: "stop_selling",
    name: i18n.t("plan_management.selling_status.stop_selling")
  }
]
