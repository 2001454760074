<template lang="pug">
  .rate-plan-filters
    .row
      .filter.col.search
        AppSearch(
          :value="filters.searchValue"
          @update="applyFilters('searchValue', $event)"
        )
    .row.filer-bar
      .filter.col
        p.filter-title {{ $t("ota_management.filters.company") }}
        AppDropdown.channel(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.companies"
          :items="companies"
          @select="applyFilters('companies', $event)"
        )
      .filter.col
        p.filter-title {{ $t("ota_management.filters.inventory_group") }}
        AppDropdown.shop(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.inventoryGroups"
          :items="inventoryGroups"
          @select="applyFilters('inventoryGroups', $event)"
        )
      .filter.col
        p.filter-title {{ $t("ota_management.filters.shop") }}
        AppDropdown.car-class(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.shops"
          :items="shops"
          @select="applyFilters('shops', $event)"
        )
      .filter.col
        .actions.align-items-center.top-offset
          AppIconButton.more-filters-button(
            title="company_system.rate_plan_settings.rate_plan.more_filters"
            icon="tune"
            :class="{ pressed: moreFiltersShown }"
            :use-app-icon="true"
            @click="handleMoreFiltersClick"
          )
          AppIconButton.clear(
            title="company_system.rate_plan_settings.rate_plan.clear"
            icon="trashXMark"
            :use-app-icon="true"
            @click="$emit('clear-filters')"
          )
    Transition(
      name="fade-dropdown"
      mode="out-in"
    )
      .row.align-items-center.more-filters(v-if="moreFiltersShown")
        .filter.col.status
          p.filter-title {{ $t("ota_management.filters.period") }}
          AppDropdown.status(
            allow-empty
            close-on-select
            :value="filters.periodType"
            :items="periodTypes"
            @select="applyFilters('periodType', $event)"
          )
        .filter.col
          AppDatepicker.date.top-offset(
            :value="filters.dateRange"
            range
            @change="applyFilters('dateRange', $event)"
          )
        .filter.col
          AppDropdown.period-inclusions.top-offset(
            allow-empty
            close-on-select
            :value="filters.periodInclusion"
            :items="periodInclusions"
            @select="applyFilters('periodInclusion', $event)"
          )
        .filter.col
          p.filter-title {{ $t("ota_management.filters.car_class") }}
          AppDropdown.sale-status(
            checkbox
            batch-select
            multiple
            allow-empty
            close-on-select
            :value="filters.carClasses"
            :items="carClasses"
            @select="applyFilters('carClasses', $event)"
          )
        .filter.col
          p.filter-title {{ $t("ota_management.filters.status") }}
          AppDropdown.sale-status(
            allow-empty
            close-on-select
            :value="filters.saleStatus"
            :items="saleStatuses"
            @select="applyFilters('saleStatus', $event)"
          )
</template>

<script>
  import { STATUSES, PERIOD_INCLUSION_TYPES, SALE_STATUSES } from "@/pages/PlanManagement/constants"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },
      companies: {
        type: Array,
        default: () => []
      },
      inventoryGroups: {
        type: Array,
        default: () => []
      },
      shops: {
        type: Array,
        default: () => []
      },
      carClasses: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        moreFiltersShown: false,
        periodTypes: STATUSES,
        periodInclusions: PERIOD_INCLUSION_TYPES,
        saleStatuses: SALE_STATUSES
      }
    },

    methods: {
      handleMoreFiltersClick() {
        this.moreFiltersShown = !this.moreFiltersShown
      },

      applyFilters(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .rate-plan-filters
    +filter-title

    padding-top: 30px

    .filer-bar
      margin-top: 20px

      .filter
        &:last-child
          display: flex
          justify-content: flex-end
          margin-right: 10px

    .top-offset
      margin-top: 32px

    .actions
      height: 32px
      width: 210px
      display: flex
      justify-content: space-between

      ::v-deep
        .app-button-content
          .title
            margin-left: 5px

      .more-filters-button
        border-color: $default-purple
        color: $default-purple

      .clear
        border-color: $default-purple
        color: $default-purple

      .pressed
        background-color: $default-purple

        ::v-deep
          svg
            path
              fill: $default-white

          .app-button-content
            .title
              color: $default-white

    .more-filters
      margin: 30px 10px 0
      background-color: $default-gray-light
      padding: 15px 5px 25px
      border-radius: 5px

      .top-offset
        margin-top: 25px

      ::v-deep
        .app-select
          background-color: $default-gray-light
</style>
